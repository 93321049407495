.App {
  min-height: 100vh;
  display: flex;
  justify-content: center;
}

.container {
  width: 1920px;
}

input[type="number"]::-webkit-inner-spin-button,
input[type="number"]::-webkit-outer-spin-button {
  -webkit-appearance: none;
  margin: 0;
}

/* This is the custom input field accross the application */
.inputField {
  color: inherit;
  background: rgba(196, 196, 196, 0.1);
  border-radius: 4px;
  padding: 10px;
}

input:-webkit-autofill,
input:-webkit-autofill:hover,
input:-webkit-autofill:focus,
input:-webkit-autofill:active {
  transition: background-color 5000s ease-in-out 0s, color 5000s ease-in-out 0s;
  transition-delay: background-color 5000s, color 5000s;
}
